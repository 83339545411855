<template>
  <!-- eslint-disable max-len -->
  <div class="prototypewindow">
    <Container>
      <div class="prototypewindow__container">
        <div class="prototypewindow__text">
          <div class="typography__title typography__title--light">Prototype Preview</div>
          <div class="typography__paragraph typography__paragraph--light">
            You can always test your prototype in the main editor, but the Prototype Preview window allows you to quickly test in the user’s context. Just add a background image, resize and position the individual devices to start user testing immediately.
          </div>
        </div>
        <img :src="require('@/assets/img/images/prototype-window.png')" class="prototypewindow__image" />
      </div>
    </Container>
  </div>
</template>

<script>
import Container from '@/components/Global/Container';

export default {
  name: 'PrototypeWindow',
  components: {
    Container,
  },
};
</script>

<style lang="scss">
  @import '~@/assets/scss/_colors.scss';
  @import '~@/assets/scss/_breakpoints.scss';

  .prototypewindow {
    width: 100%;
    background-color: $gray4;
    height: 450px;
    margin-bottom: 200px;

    @include breakpoint(smalldesktop) {
      height: 300px;
    }

    @include breakpoint(desktop) {
      height: 350px;
    }

    @include breakpoint(mobile) {
      height: 500px;
      margin-bottom: 25px;
    }

    &__container {
      display: grid;
      grid-template-columns: 300px 1fr;
      grid-column-gap: 100px;

      @include breakpoint(mobile) {
        grid-template-columns: 1fr;
        grid-auto-rows: min-content;
      }
    }

    &__image {
      width: 100%;
      margin-top: -100px;

      @include breakpoint(smalldesktop) {
        margin-top: -25px;
      }

      @include breakpoint(mobile) {
        grid-row: 1;
        margin-top: -50px;
      }

      @include breakpoint(desktop) {
        margin-top: -50px;
      }
    }

    &__text {
      margin-top: 100px;

      @include breakpoint(smalldesktop) {
        margin-top: 50px;
      }
    }
  }
</style>
