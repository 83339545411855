<template>
  <!-- eslint-disable max-len -->
  <div class="userinterface">
    <Container>
      <div class="userinterface__container">
        <img :src="require('@/assets/img/images/userinterface.png')" class="userinterface__image" />
        <div class="userinterface__text">
          <div class="typography__title">User Interface</div>
          <div class="typography__paragraph">
            The main part of the application is split into two segments: the Flow Editor and the device overview. You can add more devices from a comprehensive library of electrotechnical sensors and actors. In the Flow Editor you build device relations and the behaviour of your prototype. You can drag and drop to add nodes and divide your prototype into multiple flows to stay organized.
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>

<script>
import Container from '@/components/Global/Container';

export default {
  name: 'UserInterface',
  components: {
    Container,
  },
};
</script>

<style lang="scss">
  @import '~@/assets/scss/_colors.scss';
  @import '~@/assets/scss/_breakpoints.scss';

  .userinterface {
    margin-top: 150px;
    width: 100%;
    background-color: $gray1;
    padding-bottom: 200px;

    @include breakpoint(smalldesktop) {
      padding-bottom: 130px;
    }

    &__container {
      display: grid;
      grid-template-columns: 1fr 300px;
      grid-column-gap: 100px;

      @include breakpoint(mobile) {
        grid-template-columns: 1fr;
        grid-auto-rows: min-content;
      }
    }

    &__image {
      width: 100%;
      margin-top: -100px;
      
      @include breakpoint(smalldesktop) {
        margin-top: -50px;
      }
    }

    &__text {
      margin-top: 100px;

      @include breakpoint(smalldesktop) {
        margin-top: 50px;
      }
    }
  }
</style>
