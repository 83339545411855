<template>
  <!-- eslint-disable max-len -->
  <div class="header">
    <Container>
      <div>
        <div class="header__title">
          <span class="title__name">Flux Studio</span> is a Prototyping Tool for Cross-Device Interactions
        </div>
        <Button
          class="header__videobutton"
          role="primary"
          text="Sign up for the beta"
          :icon="require('@/assets/img/icons/mail.svg')"
          :onClick="openMailSignupPage"
        />
      </div>
      <div
        class="header__hero"
        :style="`background-image: url(${require('@/assets/img/images/hero.jpg')})`"
      />
    </Container>
  </div>
</template>

<script>
import Container from '@/components/Global/Container';
import Button from '@/components/Global/Button';

export default {
  name: 'Header',
  components: {
    Container,
    Button,
  },

  methods: {
    openMailSignupPage() {
      window.open('http://eepurl.com/gjoPMb', '_blank');
    },
  },
};
</script>

<style lang="scss">
  @import '~@/assets/scss/_colors.scss';
  @import '~@/assets/scss/_breakpoints.scss';

  .header {
    width: 100%;
    height: 600px;
    background-color: $gray1;

    @include breakpoint(mobile) {
      height: 310px;
    }

    .container {
      display: grid;
      grid-template-columns: 38% 62%;

      @include breakpoint(mobile) {
        grid-template-columns: 1fr;
      }
    }

    &__title {
      position: relative;
      font-size: 2.5em;
      letter-spacing: -0.02em;
      padding-top: 100px;
      margin-bottom: 50px;

      @include breakpoint(mobile) {
        padding-top: 50px;
      }

      .title {
        &__name {
          font-weight: 600;
          color: $primary;
        }
      }
    }

    &__hero {
      height: 100%;
      width: 1280px;
      background-size: cover;
      background-position: 0 25%;
      margin-left: -150px;

      @include breakpoint(mobile) {
        display: none;
      }
    }

    @include breakpoint(mobile) {
      &__videobutton {
        position: absolute;
        bottom: -15px;
      }
    }
  }
</style>
