<template>
  <!-- eslint-disable max-len -->
  <InfoBlock
      topic="Foundations"
      title="Types of Cross-Device Interactions"
    >
      <div>
        <div class="typography__title xditype__title">{{ this.xdiTypes[this.currentType].title }}</div>
        <div class="typography__paragraph">{{ this.xdiTypes[this.currentType].text }}</div>
      </div>
      <div>
        <Graphic :img="require(`@/assets/img/images/${ this.xdiTypes[this.currentType].image}`)" />
        <div class="foundations__navarrow foundations__navarrow--left" @click="previousItem">&larr;</div>
        <div class="foundations__navarrow foundations__navarrow--right" @click="nextItem">&rarr;</div>
      </div>
    </InfoBlock>
</template>

<script>
import InfoBlock from '@/components/LayoutElements/InfoBlock.vue';
import Graphic from '@/components/LayoutElements/Graphic.vue';

export default {
  name: 'Foundations',
  components: {
    InfoBlock,
    Graphic,
  },

  data: () => ({
    currentType: 0,

    xdiTypes: [
      {
        title: 'I. Device Independent Environment',
        text: 'Many online services offer a continuous workflow by synchronizing information across devices and saving data on their cloud platform. Google\'s G Suite is accessible from many different types of devices, browsers synchronize history, tabs and bookmarks and Spotify allows to actively push music playback from one devices to another.',
        image: 'type01.png',
      },
      {
        title: 'II. Control',
        text: 'Smartphones have not only become portable phones with some extra features, but we can also use them to dim lights and control devices like TVs and set-top-boxes. This market involves interactions that leverage multiple devices while forming one perceived unit to the user (like a dial and display on a washing machine).',
        image: 'type02.png',
      },
      {
        title: 'III. Functionality Extension',
        text: 'Often when we\'re not satisfied with the functionality of one device, we have the opportunity to extend it by leveraging features from another. For example, when we hold a presentation we connect our laptop to a projector to increase the screen size. Even some video games can display more information when a tablet or second screen is connected.',
        image: 'type03.png',
      },
      {
        title: 'IV. Multi-Device Reliance',
        text: 'Some services require users to actively switch between multiple devices in a short amount of time. This includes pairing bluetooth devices, sharing screens through an Apple TV and verifying our identities with single-use passcodes for Two-Factor-Authentication.',
        image: 'type04.png',
      },
    ],
  }),

  methods: {
    nextItem() {
      this.currentType += 1;

      if (this.currentType >= this.xdiTypes.length) this.currentType = 0;
    },

    previousItem() {
      this.currentType -= 1;

      if (this.currentType < 0) this.currentType = this.xdiTypes.length - 1;
    },
  },
};
</script>

<style lang="scss">
  @import '~@/assets/scss/_colors.scss';
  @import '~@/assets/scss/_breakpoints.scss';

  .foundations {
    &__navarrow {
      position: absolute;
      font-size: 2em;
      color: $gray2;
      top: 75px;
      cursor: pointer;
      user-select: none;

      &:hover {
        color: $gray3;
      }

      &--left {
        left: -70px;

        @include breakpoint(smalldesktop) {
          left: -40px;
        }

        @include breakpoint(mobile) {
          right: 50px;
          left: unset;
          top: 0;
        }
      }

      &--right {
        left: 390px;
        
        @include breakpoint(smalldesktop) {
          left: 360px;
        }

        @include breakpoint(mobile) {
          left: unset;
          right: 0;
          top: 0;
        }
      }
    }
  }

  @include breakpoint(mobile) {
    .xditype__title {
      max-width: calc(100% - 100px);
      height: 2.4em;
    }
  }
</style>
