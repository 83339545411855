<template>
  <!-- eslint-disable max-len -->
  <div class="infoblock">
    <Container :enableGrid="true" :enableGap="true">
      <div class="infoblock__heading">
        <div class="heading__topic">{{ this.topic }}</div>
        <div class="heading__title">{{ this.title }}</div>
      </div>
    </Container>
    <Container :enableGrid="true" :enableGap="true">
      <slot />
    </Container>
  </div>
</template>

<script>
import Container from '@/components/Global/Container';

export default {
  name: 'InfoBlock',
  components: {
    Container,
  },

  props: {
    topic: String,
    title: String,
  },
};
</script>

<style lang="scss">
  @import '~@/assets/scss/_colors.scss';
  @import '~@/assets/scss/_breakpoints.scss';

  .infoblock {
    &__heading {
      margin-bottom: 50px;

      .heading {
        &__title {
          font-size: 2em;
          color: $primary;
          font-weight: 600;
        }

        &__topic {
          position: absolute;
          width: 100%;
          font-size: 2em;
          color: $gray2;
          font-weight: 400;
          text-align: right;
          margin-left: calc(-100% - 40px);

          @include breakpoint(smalldesktop) {
            margin-left: 0;
            text-align: left;
            margin-top: -1.3em;
          }
        }
      }
    }
  }
</style>
