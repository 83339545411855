<template>
  <!-- eslint-disable-max-len -->
  <div>
    <Container>
      <div class="promovideo">
        <iframe src="https://player.vimeo.com/video/314321435?color=F2B611&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
      </div>
    </Container>
  </div>
</template>

<script>
import Container from '@/components/Global/Container';

export default {
  name: 'PromoVideo',
  components: {
    Container,    
  },
};
</script>

<style lang="scss">
  .promovideo {
    padding: 56.25% 0 0 0;
    position: relative;
  }
</style>
