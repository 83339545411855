<template>
  <div id="app">
    <Header />
    <Spacing />
    <PromoVideo />
    <Spacing />
    <Concept />
    <UserInterface />
    <PrototypeWindow />
    <Spacing />
    <Foundations />
    <Spacing />
    <Information />
    <Spacing />    
    <MoreInformation />
    <Spacing />
    <Footer />
    <Spacing :small="true" />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

import Spacing from '@/components/LayoutElements/Spacing.vue';

import PromoVideo from '@/components/Composed/PromoVideo.vue';
import Concept from '@/components/Composed/Concept.vue';
import UserInterface from '@/components/Composed/UserInterface.vue';
import PrototypeWindow from '@/components/Composed/PrototypeWindow.vue';
import Foundations from '@/components/Composed/Foundations.vue';
import Information from '@/components/Composed/Information.vue';
import MoreInformation from '@/components/Composed/MoreInformation.vue';

export default {
  name: 'app',
  components: {
    Header,
    Footer,

    Spacing,

    PromoVideo,
    Concept,
    UserInterface,
    PrototypeWindow,
    Foundations,
    Information,
    MoreInformation,
  },
};
</script>

<style lang="scss">
  @import '~@/assets/scss/_colors.scss';
  @import '~@/assets/scss/_typography.scss';
  @import '~@/assets/scss/_breakpoints.scss';

  body, html {
    width: 100%;
    height: 100%;
    margin: 0;
  }

  #app {
    font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    letter-spacing: -0.2px;
    color: $gray4;
    overflow: hidden;

    @include breakpoint(smallmobile) {
      font-size: 0.9em;
    }

    a {
      color: $gray3;
      text-decoration: none;
      transition: color 0.15s ease;

      &:hover {
        color: $primaryDark;
      }
    }
  }
</style>
