<template>
  <!-- eslint-disable max-len -->
  <InfoBlock
      topic="Concept"
      title="Define Relations Between Devices"
    >
      <div>
        <div class="typography__title">Prototyping Cross-Device Experiences</div>
        <div class="typography__paragraph">
          Flux Studio is built around the idea that you can visually connect devices' states together. Instead of writing code or defining linear flows, in Flux you can build reactive prototypes that work between multiple devices. For example, when building an interaction where a display reacts to a dial's (potentiometer's) rotation, you simply assign states to both devices and link them visually.
        </div>
      </div>
      <div>
        <Graphic :img="require('@/assets/img/images/relation.png')" />
      </div>
    </InfoBlock>
</template>

<script>
import InfoBlock from '@/components/LayoutElements/InfoBlock.vue';
import Graphic from '@/components/LayoutElements/Graphic.vue';

export default {
  name: 'Concept',
  components: {
    InfoBlock,
    Graphic,
  },
};
</script>

<style lang="scss">
  
</style>
