<template>
  <!-- eslint-disable max-len -->
  <div class="spacing" :class="{ 'spacing--small': this.small }" />
</template>

<script>
export default {
  name: 'Spacing',
  components: {
    
  },

  props: {
    small: Boolean,
  },
};
</script>

<style lang="scss">
  @import '~@/assets/scss/_breakpoints.scss';

  .spacing {
    width: 0px;
    height: 175px;

    &--small {
      height: 100px;
    }

    @include breakpoint(mobile) {
      height: 100px;

      &--small {
        height: 50px;
      }
    }
  }
</style>
