<template>
  <!-- eslint-disable max-len -->
  <img :src="this.img" class="graphic" />
</template>

<script>
export default {
  name: 'Graphic',
  components: {
    
  },

  props: {
    img: String,
  },
};
</script>

<style lang="scss">
  .graphic {
    width: 100%;
    max-width: 800px;
    margin-top: -50px;
  }
</style>
