<template>
  <!-- eslint-disable max-len -->
  <div :class="`button button--${this.role} ${this.icon ? 'button--withicon' : ''}`" @click="(event) => { if (this.onClick) onClick(event); }">
    <div v-if="this.icon" class="button__icon">
      <img :src="this.icon" class="icon__img" />
    </div>
    {{ this.text }}
  </div>
</template>

<script>
export default {
  name: 'Button',
  components: {
    
  },

  props: {
    text: String,
    role: String,
    icon: String,
    onClick: Function,
  },
};
</script>

<style lang="scss">
  @import '~@/assets/scss/_colors.scss';
  @import '~@/assets/scss/_breakpoints.scss';

  .button {
    display: inline-block;
    padding: 8px 15px;
    border: 1px solid transparent;
    border-radius: 5px;
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.15);
    font-weight: 500;
    position: relative;
    cursor: pointer;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 0.85;
    }

    &--primary {
      background-color: $primary;
      border-color: $primaryDark;
      color: $gray0;

      .button__icon {
        background-color: $primaryDark;
      }
    }

    &--withicon {
      padding-left: 48px;
    }

    &__icon {
      position: absolute;
      width: 26px;
      height: 26px;
      border-radius: 26px;
      margin: -4px 0 0 -36px;

      .icon__img {
        width: 16px;
        height: 16px;
        position: absolute;
        top: calc(50% - 16px / 2);
        left: calc(50% - 16px / 2);
      }
    }
  }
</style>
