<template>
  <!-- eslint-disable max-len -->
  <div class="footer">
    <Container :enableGrid="true">
      <div class="footer__overline" />
      <div class="footer__author">
        <div class="author__copyright">&copy; {{ this.copyrightYear }} Jan Hassel</div>
        <div class="author__contact">
          <a href="https://janhassel.de" target="_blank">https://janhassel.de</a><br>
          <a href="mailto:hello@janhassel.de">hello@janhassel.de</a>
        </div>
      </div>
      <div class="footer__legal">
        <br>
        <a href="https://janhassel.de/imprint" target="_blank">Imprint</a><br>
        <a href="https://janhassel.de/privacy" target="_blank">Privacy Policy</a>
      </div>
    </Container>
  </div>
</template>

<script>
import Container from '@/components/Global/Container';

export default {
  name: 'Footer',
  components: {
    Container,
  },

  computed: {
    copyrightYear() {
      const foundingYear = 2019;
      const currentYear = new Date().getFullYear();

      return foundingYear !== currentYear ? `${foundingYear} - ${currentYear}` : foundingYear;
    },
  },
};
</script>

<style lang="scss">
  @import '~@/assets/scss/_colors.scss';
  @import '~@/assets/scss/_breakpoints.scss';

  .footer {
    line-height: 1.5em;

    &__overline {
      grid-column: 1 / span 2;
      border-top: 2px solid $gray1;
      margin-bottom: 50px;
    }

    &__author {      
      .author {
        &__copyright {
          font-weight: 600;
          color: $gray4;
        }
      }
    }

    &__legal {
      text-align: right;
    }
  }
</style>
