<template>
  <!-- eslint-disable max-len -->
  <InfoBlock
      topic="Information"
      title="About This Project"
    >
      <div>
        <div class="typography__title">Flux Studio</div>
        <div class="typography__paragraph">
          Flux Studio is the result of my Bachelor Thesis "Prototyping Cross-Device Experiences - A concept for prototyping in the era of distributed user interfaces" which I worked on between October 2018 and Februar 2019. The Bachelor thesis was part of my studies at the University of Design in Schwäbisch Gmünd, Germany where I received a Bachelor of Arts from the "Internet of Things - Design of Networked Systems" program.
        </div>
      </div>
      <div>
        
      </div>
    </InfoBlock>
</template>

<script>
import InfoBlock from '@/components/LayoutElements/InfoBlock.vue';

export default {
  name: 'Information',
  components: {
    InfoBlock,
  },
};
</script>

<style lang="scss">
  
</style>
