<template>
  <!-- eslint-disable max-len -->
  <div class="moreinfo">
    <Container :enableGrid="true">
      <div class="moreinfo__left" :style="`background-image: url(${require('@/assets/img/images/promo.jpg')})`" />
      <div class="moreinfo__right" :style="`background-image: url(${require('@/assets/img/images/thesis.jpg')})`">
        <div class="right__container">
          <div class="typography__title">
            If you want to learn more about the concept and the process that led to Flux Studio, feel free to take a look at the thesis.
          </div>
          <Button
            class="thesisbutton"
            role="primary"
            text="Download the Thesis"
            :icon="require('@/assets/img/icons/download.svg')"
            :onClick="openThesis"
          />
        </div>
      </div>
    </Container>
  </div>
</template>

<script>
import Container from '@/components/Global/Container';
import Button from '@/components/Global/Button';

export default {
  name: 'MoreInformation',
  components: {
    Container,
    Button,
  },

  methods: {
    openThesis() {
      window.location = `${window.location.origin}/prototyping-cross-device-experiences.pdf`;
    },
  },
};
</script>

<style lang="scss">
  @import '~@/assets/scss/_breakpoints.scss';

  .moreinfo {
    height: 300px;

    @include breakpoint(mobile) {
      height: unset;
    }

    &__left,
    &__right {
      background-size: cover;
    }

    &__left {
      background-position: 50% 25%;
      border-radius: 5px 0 0 5px;
      
      @include breakpoint(mobile) {
        height: 200px;
        border-radius: 5px 5px 0 0;
      }
    }

    &__right {
      background-position: 100% 100%;
      border-radius: 0 5px 5px 0;
      background-color: #eeeeee;

      @include breakpoint(mobile) {
        height: 400px;
        border-radius: 0 0 5px 5px;
        background-size: 125%;
        background-repeat: no-repeat;
      }

      .right__container {
        width: 40%;
        height: calc(100% - 100px);
        position: relative;
        top: 50px;
        left: 50px;

        @include breakpoint(smalldesktop) {
          height: calc(100% - 60px);
          top: 30px;
          left: 30px;
        }

        @include breakpoint(mobile) {
          width: 80%;
        }

        .thesisbutton {
          position: absolute;
          bottom: 0;

          @include breakpoint(mobile) {
            bottom: calc(-30px - 15px);
            width: 100%;
            box-sizing: border-box;
          }
        }
      }
    }
  }
</style>
