<template>
  <!-- eslint-disable max-len -->
  <div class="container" :class="{ 'container--grid': this.enableGrid, 'container--gridgap': this.enableGrid && this.enableGap }">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Container',
  components: {
    
  },

  props: {
    enableGrid: Boolean,
    enableGap: Boolean,
  }
};
</script>

<style lang="scss">
  @import '~@/assets/scss/_breakpoints.scss';

  .container {
    height: 100%;
    width: 70%;
    max-width: 1440px;
    margin: 0 auto;
    position: relative;
    
    &--grid {
      display: grid;
      grid-template-columns: 38fr 62fr;

      @include breakpoint(mobile) {
        grid-template-columns: 1fr;
        grid-auto-rows: min-content;
      }
    }

    &--gridgap {
      grid-column-gap: 100px;

      @include breakpoint(mobile) {
        grid-column-gap: 0;
        grid-row-gap: 75px;
      }
    }

    @include breakpoint(smalldesktop) {
      width: 80%;
    }

    @include breakpoint(mobile) {
      width: 85%;
    }

    @include breakpoint(smallmobile) {
      width: 90%;
    }
  }
</style>
